.viewImage{
	width: 100%;
	
	
    margin: auto;
}
.textContent{
    padding: 20px;
}
.dataLeft{
    text-align: left;
}
.dataRight{
    text-align: Right;
}